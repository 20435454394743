const kecamatan = [
    { key: 'Jatinangor', value:'jatinangor' },
    { key: 'Cimanggung', value: 'cimanggung' },
    { key: 'Tanjungsari', value: 'tanjungsari' },
    { key: 'Sukasari', value: 'sukasari' },
    { key: 'Pamulihan', value: 'pamulihan' },
    { key: 'Rancakalong', value: 'rancakalong' },
    { key: 'Sumedang Selatan', value: 'sumedang selatan'},
    { key: 'Sumedang Utara', value: 'sumedang utara'},
    { key: 'Ganeas', value: 'ganeas' },
    { key: 'Situraja', value: 'situraja' },
    { key: 'Cisitu', value: 'cisitu' },
    { key: 'Darmaraja', value: 'darmaraja' },
    { key: 'Cibugel', value: 'cibugel' },
    { key: 'Wado', value: 'wado' },
    { key: 'Jatinunggal', value: 'jatinunggal' },
    { key: 'Jatigede', value: 'jatigede' },
    { key: 'Tomo', value: 'tomo' },
    { key: 'Ujungjaya', value: 'ujungjaya' },
    { key: 'Conggeang', value: 'conggeang' },
    { key: 'Paseh', value: 'paseh' },
    { key: 'Cimalaka', value: 'cimalaka' },
    { key: 'Cisarua', value: 'cisarua' },
    { key: 'Tanjungkerta', value: 'tanjungkerta' },
    { key: 'Tanjungmedar', value: 'tanjungmedar' },
    { key: 'Buahdua', value: 'buahdua' },
    { key: 'Surian', value: 'surian' },
]
export const desa = [
    { key: 'CIPACING', value: 'cipacing' },
    { key: 'MEKARGALIH', value: 'mekargalih' },
    { key: 'SAYANG', value: 'sayang' },
    { key: 'CINTA MULYA', value: 'cinta mulya' },
    { key: 'CISEMPUR', value: 'cisempur' },
    { key: 'JATIMUKTI', value: 'jatimukti' },
    { key: 'JATIROKE', value: 'jatiroke' },
    { key: 'HEGARMANAH', value: 'hegarmanah' },
    { key: 'CIKERUH', value: 'cikeruh' },
    { key: 'CIBEUSI', value: 'cibeusi' },
    { key: 'CILELES', value: 'cileles' },
    { key: 'CILAYUNG', value: 'cilayung' },
    { key: 'MANGUNARGA', value: 'mangunarga' },
    { key: 'SAWAHDADAP', value: 'sawahdadap' },
    { key: 'SUKADANA', value: 'sukadana' },
    { key: 'CIHANJUANG', value: 'cihanjuang' },
    { key: 'CIKAHURIPAN', value: 'cikahuripan' },
    { key: 'SINDANGGALIH', value: 'sindanggalih' },
    { key: 'SINDANGPAKUON', value: 'sindangpakuon' },
    { key: 'CIMANGGUNG', value: 'cimanggung' },
    { key: 'TEGALMANGGUNG', value: 'tegalmanggung' },
    { key: 'SINDULANG', value: 'sindulang' },
    { key: 'PASIRNANJUNG', value: 'pasirnanjung' },
    { key: 'CINANJUNG', value: 'cinanjung' },
    { key: 'RAHARJA', value: 'raharja' },
    { key: 'GUNUNGMANIK', value: 'gunungmanik' },
    { key: 'MARGA JAYA', value: 'marga jaya' },
    { key: 'TANJUNGSARI', value: 'tanjungsari' },
    { key: 'JATISARI', value: 'jatisari' },
    { key: 'KUTAMANDIRI', value: 'kutamandiri' },
    { key: 'MARGALUYU', value: 'margaluyu' },
    { key: 'GUDANG', value: 'gudang' },
    { key: 'PASIGARAN', value: 'pasigaran' },
    { key: 'KADAKAJAYA', value: 'kadakajaya' },
    { key: 'CIJAMBU', value: 'cijambu' },
    { key: 'SUKARAPIH', value: 'sukarapih' },
    { key: 'SUKASARI', value: 'sukasari' },
    { key: 'MEKARSARI', value: 'mekarsari' },
    { key: 'SINDANGSARI', value: 'sindangsari' },
    { key: 'NANGGERANG', value: 'nanggerang' },
    { key: 'BANYURESMI', value: 'banyuresmi' },
    { key: 'GENTENG', value: 'genteng' },
    { key: 'MEKARBAKTI', value: 'mekarbakti' },
    { key: 'CILEMBU', value: 'cilembu' },
    { key: 'CIMARIAS', value: 'cimarias' },
    { key: 'CINANGGERANG', value: 'cinanggerang' },
    { key: 'CIJERUK', value: 'cijeruk' },
    { key: 'CIGENDEL', value: 'cigendel' },
    { key: 'HAURNGOMBONG', value: 'haurngombong' },
    { key: 'CIPTASARI', value: 'ciptasari' },
    { key: 'CITALI', value: 'citali' },
    { key: 'PAMULIHAN', value: 'pamulihan' },
    { key: 'SUKAWANGI', value: 'sukawangi' },
    { key: 'SUKASIRNARASA', value: 'sukasirnarasa' },
    { key: 'PASIR BIRU', value: 'pasir biru' },
    { key: 'RANCAKALONG', value: 'rancakalong' },
    { key: 'PAMEKARAN', value: 'pamekaran' },
    { key: 'SUKAMAJU', value: 'sukamaju' },
    { key: 'SUKAHAYU', value: 'sukahayu' },
    { key: 'NAGARAWANGI', value: 'nagarawangi' },
    { key: 'CIBUNAR', value: 'cibunar' },
    { key: 'PANGADEGAN', value: 'pangadegan' },
    { key: 'CIBUNGUR', value: 'cibungur' },
    { key: 'SUKAJAYA', value: 'sukajaya' },
    { key: 'MARGAMEKAR', value: 'margamekar' },
    { key: 'CIPANCAR', value: 'cipancar' },
    { key: 'CITENGAH', value: 'citengah' },
    { key: 'GUNASARI', value: 'gunasari' },
    { key: 'BAGINDA', value: 'baginda' },
    { key: 'SUKAGALIH', value: 'sukagalih' },
    { key: 'CIPAMEUNGPEUK', value: 'cipameungpeuk' },
    { key: 'REGOL WETAN', value: 'regol wetan' },
    { key: 'KOTAKULON', value: 'kotakulon' },
    { key: 'PASANGGRAHAN BARU', value: 'pasanggrahan baru'},
    { key: 'CIHERANG', value: 'ciherang' },
    { key: 'MEKARRAHAYU', value: 'mekarrahayu' },
    { key: 'MARGALAKSANA', value: 'margalaksana' },
    { key: 'SIRNAMULYA', value: 'sirnamulya' },
    { key: 'GIRIMUKTI', value: 'girimukti' },
    { key: 'MULYASARI', value: 'mulyasari' },
    { key: 'PADASUKA', value: 'padasuka' },
    { key: 'MARGAMUKTI', value: 'margamukti' },
    { key: 'MEKARJAYA', value: 'mekarjaya' },
    { key: 'JATIMULYA', value: 'jatimulya' },
    { key: 'JATIHURIP', value: 'jatihurip' },
    { key: 'KEBONJATI', value: 'kebonjati' },
    { key: 'SITU', value: 'situ' },
    { key: 'KOTAKALER', value: 'kotakaler' },
    { key: 'TALUN', value: 'talun' },
    { key: 'RANCAMULYA', value: 'rancamulya' },
    { key: 'CIKONDANG', value: 'cikondang' },
    { key: 'TANJUNGHURIP', value: 'tanjunghurip' },
    { key: 'DAYEUH LUHUR', value: 'dayeuh luhur' },
    { key: 'CIKONENG', value: 'cikoneng' },
    { key: 'SUKAWENING', value: 'sukawening' },
    { key: 'GANEAS', value: 'ganeas' },
    { key: 'SUKALUYU', value: 'sukaluyu' },
    { key: 'BANGBAYANG', value: 'bangbayang' },
    { key: 'KADUWULUNG', value: 'kaduwulung' },
    { key: 'CIJATI', value: 'cijati' },
    { key: 'MEKARMULYA', value: 'mekarmulya' },
    { key: 'CIKADU', value: 'cikadu' },
    { key: 'KARANGHEULEUT', value: 'karangheuleut' },
    { key: 'CIJELER', value: 'cijeler' },
    { key: 'AMBIT', value: 'ambit' },
    { key: 'SUKATALI', value: 'sukatali' },
    { key: 'SITURAJA', value: 'situraja' },
    { key: 'JATIMEKAR', value: 'jatimekar' },
    { key: 'SITURAJA UTARA', value: 'situraja utara'},
    { key: 'MALAKA', value: 'malaka' },
    { key: 'WANAKERTA', value: 'wanakerta' },
    { key: 'CICARIMANAH', value: 'cicarimanah' },
    { key: 'SUNDAMEKAR', value: 'sundamekar' },
    { key: 'CIMARGA', value: 'cimarga' },
    { key: 'CINANGSI', value: 'cinangsi' },
    { key: 'LINGGAJAYA', value: 'linggajaya' },
    { key: 'SITUMEKAR', value: 'situmekar' },
    { key: 'CISITU', value: 'cisitu' },
    { key: 'CIGINTUNG', value: 'cigintung' },
    { key: 'RANJENG', value: 'ranjeng' },
    { key: 'CILOPANG', value: 'cilopang' },
    { key: 'PAJAGAN', value: 'pajagan' },
    { key: 'NEGLASARI', value: 'neglasari' },
    { key: 'SUKAMENAK', value: 'sukamenak' },
    { key: 'JATIBUNGUR', value: 'jatibungur' },
    { key: 'DARMAJAYA', value: 'darmajaya' },
    { key: 'DARMARAJA', value: 'darmaraja' },
    { key: 'CIPEUTEUY', value: 'cipeuteuy' },
    { key: 'CIKEUSI', value: 'cikeusi' },
    { key: 'CIEUNTEUNG', value: 'cieunteung' },
    { key: 'TARUNAJAYA', value: 'tarunajaya' },
    { key: 'SUKARATU', value: 'sukaratu' },
    { key: 'LEUWIHIDEUNG', value: 'leuwihideung' },
    { key: 'CIBOGO', value: 'cibogo' },
    { key: 'CIPAKU', value: 'cipaku' },
    { key: 'KARANG PAKUAN', value: 'karang pakuan' },
    { key: 'PAKU ALAM', value: 'paku alam' },
    { key: 'RANGGON', value: 'ranggon' },
    { key: 'BUANA MEKAR', value: 'buana mekar' },
    { key: 'JAYA MEKAR', value: 'jaya mekar' },
    { key: 'CIBUGEL', value: 'cibugel' },
    { key: 'TAMANSARI', value: 'tamansari' },
    { key: 'SUKARAJA', value: 'sukaraja' },
    { key: 'CIPASANG', value: 'cipasang' },
    { key: 'JAYAMANDIRI', value: 'jayamandiri' },
    { key: 'CILENGKRANG', value: 'cilengkrang' },
    { key: 'SUKAJADI', value: 'sukajadi' },
    { key: 'GANJARESIK', value: 'ganjaresik' },
    { key: 'CIMUNGKAL', value: 'cimungkal' },
    { key: 'MULYAJAYA', value: 'mulyajaya' },
    { key: 'CIKAREO SELATAN',value: 'cikareo selatan'},
    { key: 'CIKAREO UTARA', value: 'cikareo utara' },
    { key: 'WADO', value: 'wado' },
    { key: 'PADAJAYA', value: 'padajaya' },
    { key: 'SUKAPURA', value: 'sukapura' },
    { key: 'CISURAT', value: 'cisurat' },
    { key: 'KIRISIK', value: 'kirisik' },
    { key: 'CIPEUNDEUY', value: 'cipeundeuy' },
    { key: 'CIMANINTIN', value: 'cimanintin' },
    { key: 'SUKAMANAH', value: 'sukamanah' },
    { key: 'BANJARSARI', value: 'banjarsari' },
    { key: 'SARIMEKAR', value: 'sarimekar' },
    { key: 'TARIKOLOT', value: 'tarikolot' },
    { key: 'SIRNASARI', value: 'sirnasari' },
    { key: 'PAWENANG', value: 'pawenang' },
    { key: 'SUKAKERSA', value: 'sukakersa' },
    { key: 'MEKARASIH', value: 'mekarasih' },
    { key: 'CIRANGGEM', value: 'ciranggem' },
    { key: 'CISAMPIH', value: 'cisampih' },
    { key: 'KADU', value: 'kadu' },
    { key: 'LEBAKSIUH', value: 'lebaksiuh' },
    { key: 'CINTAJAYA', value: 'cintajaya' },
    { key: 'CIPICUNG', value: 'cipicung' },
    { key: 'JEMAH', value: 'jemah' },
    { key: 'CIJEUNGJING', value: 'cijeungjing' },
    { key: 'KADUJAYA', value: 'kadujaya' },
    { key: 'KAREDOK', value: 'karedok' },
    { key: 'CIPELES', value: 'cipeles' },
    { key: 'DARMAWANGI', value: 'darmawangi' },
    { key: 'JEMBARWANGI', value: 'jembarwangi' },
    { key: 'MARONGGE', value: 'marongge' },
    { key: 'TOLENGAS', value: 'tolengas' },
    { key: 'TOMO', value: 'tomo' },
    { key: 'KARYAMUKTI', value: 'karyamukti' },
    { key: 'BUGEL', value: 'bugel' },
    { key: 'CIPELANG', value: 'cipelang' },
    { key: 'PALABUAN', value: 'palabuan' },
    { key: 'KEBON CAU', value: 'kebon cau' },
    { key: 'KUDANGWANGI', value: 'kudangwangi' },
    { key: 'SUKAMULYA', value: 'sukamulya' },
    { key: 'PALASARI', value: 'palasari' },
    { key: 'UJUNGJAYA', value: 'ujungjaya' },
    { key: 'SAKURJAYA', value: 'sakurjaya' },
    { key: 'CIBULUH', value: 'cibuluh' },
    { key: 'NARIMBANG', value: 'narimbang' },
    { key: 'JAMBU', value: 'jambu' },
    { key: 'CIPAMEKAR', value: 'cipamekar' },
    { key: 'CONGGEANG KULON', value: 'conggeang kulon'},
    { key: 'CONGGEANG WETAN', value: 'conggeang wetan'},
    { key: 'CIBEUREUYEUH', value: 'cibeureuyeuh' },
    { key: 'PADAASIH', value: 'padaasih' },
    { key: 'BABAKAN ASEM', value: 'babakan asem' },
    { key: 'UNGKAL', value: 'ungkal' },
    { key: 'CACABAN', value: 'cacaban' },
    { key: 'KARANGLAYUNG', value: 'karanglayung' },
    { key: 'CIBUBUAN', value: 'cibubuan' },
    { key: 'LEGOK KALER', value: 'legok kaler' },
    { key: 'LEGOK KIDUL', value: 'legok kidul' },
    { key: 'PASEH KIDUL', value: 'paseh kidul' },
    { key: 'CIJAMBE', value: 'cijambe' },
    { key: 'PASIREUNGIT', value: 'pasireungit' },
    { key: 'PADANAAN', value: 'padanaan' },
    { key: 'BONGKOK', value: 'bongkok' },
    { key: 'PASEH KALER', value: 'paseh kaler' },
    { key: 'HAURKUNING', value: 'haurkuning' },
    { key: 'CITEPOK', value: 'citepok' },
    { key: 'CIMUJA', value: 'cimuja' },
    { key: 'CIBEUREUM WETAN', value: 'cibeureum wetan'},
    { key: 'CIBEUREUM KULON', value: 'cibeureum kulon'},
    { key: 'MANDALAHERANG', value: 'mandalaherang' },
    { key: 'CIMALAKA', value: 'cimalaka' },
    { key: 'SERANG', value: 'serang' },
    { key: 'GALUDRA', value: 'galudra' },
    { key: 'CIKOLE', value: 'cikole' },
    { key: 'TRUNAMANGGALA', value: 'trunamanggala' },
    { key: 'NYALINDUNG', value: 'nyalindung' },
    { key: 'NALUK', value: 'naluk' },
    { key: 'CITIMUN', value: 'citimun' },
    { key: 'LICIN', value: 'licin' },
    { key: 'PADASARI', value: 'padasari' },
    { key: 'CISALAK', value: 'cisalak' },
    { key: 'KEBONKALAPA', value: 'kebonkalapa' },
    { key: 'CISARUA', value: 'cisarua' },
    { key: 'BANTARMARA', value: 'bantarmara' },
    { key: 'CIUYAH', value: 'ciuyah' },
    { key: 'CIMARA', value: 'cimara' },
    { key: 'CIPANDANWANGI', value: 'cipandanwangi' },
    { key: 'TANJUNGMEKAR', value: 'tanjungmekar' },
    { key: 'CIGENTUR', value: 'cigentur' },
    { key: 'GUNTURMEKAR', value: 'gunturmekar' },
    { key: 'CIPANAS', value: 'cipanas' },
    { key: 'BANYUASIH', value: 'banyuasih' },
    { key: 'MULYAMEKAR', value: 'mulyamekar' },
    { key: 'SUKAMANTRI', value: 'sukamantri' },
    { key: 'KERTAHARJA', value: 'kertaharja' },
    { key: 'KERTAMEKAR', value: 'kertamekar' },
    { key: 'TANJUNGMULYA', value: 'tanjungmulya' },
    { key: 'BOROS', value: 'boros' },
    { key: 'WARGALUYU', value: 'wargaluyu' },
    { key: 'TANJUNGWANGI', value: 'tanjungwangi' },
    { key: 'SUKAMUKTI', value: 'sukamukti' },
    { key: 'CIKARAMAS', value: 'cikaramas' },
    { key: 'KERTAMUKTI', value: 'kertamukti' },
    { key: 'SUKATANI', value: 'sukatani' },
    { key: 'KAMAL', value: 'kamal' },
    { key: 'JINGKANG', value: 'jingkang' },
    { key: 'TANJUNGMEDAR', value: 'tanjungmedar' },
    { key: 'SEKARWANGI', value: 'sekarwangi' },
    { key: 'CILANGKAP', value: 'cilangkap' },
    { key: 'CIBITUNG', value: 'cibitung' },
    { key: 'CIKURUBUK', value: 'cikurubuk' },
    { key: 'BOJONGLOA', value: 'bojongloa' },
    { key: 'NAGRAK', value: 'nagrak' },
    { key: 'PANYINDANGAN', value: 'panyindangan' },
    { key: 'BUAHDUA', value: 'buahdua' },
    { key: 'GENDEREH', value: 'gendereh' },
    { key: 'CITALEUS', value: 'citaleus' },
    { key: 'MEKARMUKTI', value: 'mekarmukti' },
    { key: 'HARIANG', value: 'hariang' },
    { key: 'KARANGBUNGUR', value: 'karangbungur' },
    { key: 'CIAWITALI', value: 'ciawitali' },
    { key: 'WANAJAYA', value: 'wanajaya' },
    { key: 'WANASARI', value: 'wanasari' },
    { key: 'PAMEKARSARI', value: 'pamekarsari' },
    { key: 'SURIAN', value: 'surian' },
    { key: 'TANJUNG', value: 'tanjung' },
    { key: 'RANGGASARI', value: 'ranggasari' },
    { key: 'SURIAMEDAL', value: 'suriamedal' },
    { key: 'SURIAMUKTI', value: 'suriamukti' }
  ]



export default kecamatan